import React, { useRef, useCallback, useMemo, useState } from 'react';
import { useQuery } from 'utils/react-query';
import classnames from 'classnames';
import queries from 'containers/Media/queries';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import LinkButton, { SecondaryButton } from 'components/uiLibrary/LinkButton';
import VideoPlayer from 'components/uiLibrary/VideoPlayer';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import ToggleButton from 'components/uiLibrary/ToggleButton';
import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import MediaCard from 'components/Globals/MediaCard';
import Breadcrumbs from 'components/uiLibrary/Breadcrumbs';
import CastingToolPromo from 'components/Globals/CastingToolPromo';
import MapView from 'components/Globals/MapView';
import useTracking from 'components/Globals/Analytics';
import { useTrackingClickStyles } from 'components/Globals/TrackingTester';

import { useDialogs } from 'utils/hooks/useDialogs';
import withLightbox from 'utils/hocs/withLightbox';
import usePageContext from 'utils/hooks/usePageContext';
import { useTranslation } from 'src/i18n';
import { TP, ENTITY_TYPE, VALID_QUERY_PARAMS } from 'constants/index';
import { getMediaSource } from 'utils/media';
import { getProductionMeta } from 'utils/productions';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import useEntityEditPermission from 'utils/hooks/useEntityEditPermission';

import { getLocation } from 'utils/globals';
import SectionBlock from 'components/Globals/SectionBlock';
import { ARTIST_PROFILE_INFO_SECTIONS, MEDIA_SECTIONS } from 'constants/consts';
import { artistEditTabValues } from 'containers/Artist/constants';
import { SECTIONS, COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import CardWithProductionInfo from '../CardWithProductionInfo';

import classes from './MediaWidget.module.scss';

const mediaImageTrackingData = {
  section: SECTIONS.MEDIA_WIDGET,
  component: COMPONENTS.COVER_PHOTO,
};

const mediaPosterTrackingData = {
  section: SECTIONS.MEDIA_WIDGET,
  component: COMPONENTS.PHOTO,
};

const mediaVideoTrackingData = {
  section: SECTIONS.MEDIA_WIDGET,
  component: COMPONENTS.VIDEO,
};

const mediaBtnTrackingData = {
  ...mediaImageTrackingData,
  component: COMPONENTS.MEDIA_BUTTON,
};

const MEDIA_SECTION_TYPE = {
  POSTER: 'POSTER',
  PHOTO: 'PHOTO',
  VIDEO: 'VIDEO',
};

const TOGGLE_LABEL = {
  label: `${TP}.MEDIA`,
  icon: <PlayCircleOutlineIcon className={classes.mediaIcon} />,
};

const MEDIA_OPTIONS = [
  {
    type: MEDIA_SECTION_TYPE.VIDEO,
    icon: <SpriteIcon icon="play_circle" />,
    asLink: true,
    value: 'videos',
  },
  {
    type: MEDIA_SECTION_TYPE.PHOTO,
    icon: <SpriteIcon icon="image" />,
    asLink: true,
    value: 'images',
  },
];

const MediaPageBtn = ({
  type,
  options,
  showDropdownAlways,
  isEmbedded,
  maxSingleMedia,
  btnLabel,
  totalMediaCount,
  isEditMode,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { isDesktop } = useDeviceTypeLayouts();
  const { navigate, entityType, entity } = usePageContext();
  const { linkProps, trackingData } = useMemo(() => {
    if (isEditMode) {
      let params = {};
      let trackingProps = {};
      switch (type) {
        case MEDIA_SECTION_TYPE.PHOTO:
          params = {
            queryParams: {
              tab: artistEditTabValues.personal,
              [VALID_QUERY_PARAMS.OPEN_MODAL]: ARTIST_PROFILE_INFO_SECTIONS.COVER_PHOTO,
            },
            scrollTo: ARTIST_PROFILE_INFO_SECTIONS.COVER_PHOTO,
          };
          trackingProps = mediaImageTrackingData;
          break;
        case MEDIA_SECTION_TYPE.POSTER:
          params = {
            queryParams: {
              tab: artistEditTabValues.personal,
              [VALID_QUERY_PARAMS.OPEN_MODAL]: ARTIST_PROFILE_INFO_SECTIONS.PROFILE_PHOTO,
            },
          };
          trackingProps = mediaPosterTrackingData;
          break;
        case MEDIA_SECTION_TYPE.VIDEO:
          params = {
            ...(entity?.stats?.showreels?.intro?.exists
              ? {
                  queryParams: {
                    tab: artistEditTabValues.videos,
                  },
                  scrollTo: MEDIA_SECTIONS.INTRO_SHOWREEL,
                }
              : {
                  queryParams: {
                    tab: artistEditTabValues.videos,
                  },
                  scrollTo: MEDIA_SECTIONS.PART_OF_PRODUCTION,
                }),
          };
          trackingProps = mediaVideoTrackingData;
          break;
        default:
          break;
      }

      return {
        linkProps: navigate.getLinkProps({
          entityType,
          entity,
          edit: true,
          ...params,
        }),
        trackingData: trackingProps,
      };
    }
    return {
      linkProps: options?.find(option => option?.type === type)?.linkProps,
    };
  }, [options, type, isEditMode, entityType, entity, navigate]);

  if (isEditMode) {
    return (
      <SecondaryButton
        leftIcon={<SpriteIcon icon="edit" />}
        disableUnderline
        {...linkProps}
        isLink
        size={isDesktop ? 'medium' : 'small'}
        styles={{
          root: classes.btnWrap,
        }}
        trackingData={{
          ...trackingData,
          subComponent: SUB_COMPONENTS.EDIT_CTA,
        }}
      >
        {(isDesktop || type !== MEDIA_SECTION_TYPE.POSTER) && t(`${TP}.m_EDIT`)}
      </SecondaryButton>
    );
  }

  return (
    <div className={classes.btnWrap}>
      {(maxSingleMedia || showDropdownAlways) && options?.length > 1 ? (
        <ToggleButton
          title={t(TOGGLE_LABEL.label, { number: totalMediaCount })}
          options={options}
          label={t(TOGGLE_LABEL.label, { number: totalMediaCount })}
          leftIcon={TOGGLE_LABEL.icon}
          isEmbedded={isEmbedded}
          trackingData={mediaBtnTrackingData}
          {...(!isDesktop && { styles: { selectedOption: classes.actionBtn } })}
          alwaysDropdown
          enableDarkMode
        />
      ) : (
        linkProps &&
        btnLabel?.trim()?.length > 0 && (
          <LinkButton
            {...linkProps}
            external={isEmbedded}
            isLink
            disableUnderline
            leftIcon={<SpriteIcon icon={type === MEDIA_SECTION_TYPE.PHOTO ? 'image' : 'play_circle'} />}
            trackingData={{
              ...(type === MEDIA_SECTION_TYPE.PHOTO ? mediaImageTrackingData : mediaVideoTrackingData),
              subComponent: SUB_COMPONENTS.VIEW_MORE_CTA,
            }}
            styles={{
              root: classnames(classes.btnWrap__btn, {
                [classes.actionBtn]: !isDesktop,
              }),
            }}
          >
            {btnLabel}
          </LinkButton>
        )
      )}
    </div>
  );
};

const MediaImage = ({
  production,
  mediaURL,
  options,
  altText,
  titleText,
  isEmbedded,
  showMediaOptionsAlways,
  onClick,
  maxSingleMedia,
  actionLabel,
  totalMediaCount,
  renderMediaWidth,
  disabledProductionInfo,
  isEditMode,
}) => {
  const ref = useRef(null);
  const { isDesktop } = useDeviceTypeLayouts();
  const imageProps = {
    src: mediaURL,
    transformations: TRANSFORMATIONS.MEDIA_WIDGET_BANNER,
    height: 296,
    width: renderMediaWidth,
    alt: altText,
    title: titleText,
    onClick,
    lazy: false,
    fetchpriority: 'high',
    preload: true,
    disableNextImage: true,
  };

  return (
    <CardWithProductionInfo
      production={production}
      disabled={disabledProductionInfo}
      trackingData={mediaImageTrackingData}
    >
      <div ref={ref} className={classnames(classes.media, classes.typeImage)}>
        <MediaCard imageProps={imageProps} imageTrackingData={mediaImageTrackingData} />
        {isDesktop && isEditMode && (
          <MediaPageBtn
            type={MEDIA_SECTION_TYPE.PHOTO}
            options={options}
            showDropdownAlways={showMediaOptionsAlways}
            isEmbedded={isEmbedded}
            maxSingleMedia={maxSingleMedia}
            btnLabel={actionLabel}
            totalMediaCount={totalMediaCount}
            isEditMode={isEditMode}
          />
        )}
      </div>
    </CardWithProductionInfo>
  );
};

const Placeholder = ({ icon, withBorder, description, linkProps, styles, trackingData }) => {
  const { navigate, entity, entityType } = usePageContext();
  const { setIsEditDisclaimerModal } = useDialogs();
  const track = useTracking();
  const trackingClasses = useTrackingClickStyles(trackingData, false);
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { activeRegistration } = useEntityEditPermission({
    entityType,
    entity,
  });

  const onClickHandler = event => {
    if (event?.stopPropagation) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (!activeRegistration?.status?.has_accepted_disclaimer) {
      setIsEditDisclaimerModal(true);
    } else {
      navigate.to(linkProps);
    }

    if (trackingData) {
      track.click(trackingData);
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className={classnames(classes.placeholder, trackingClasses, {
        [classes?.placeholder__withDashedBorder]: !!withBorder,
        [styles?.root]: !!styles?.root,
      })}
      onClick={onClickHandler}
      onKeyDown={onClickHandler}
    >
      <SpriteIcon
        icon={icon}
        size={36}
        className={classnames(classes.placeholder__icon, {
          [styles?.icon]: !!styles?.icon,
        })}
      />
      <Typography
        size="12"
        color="secondary"
        className={classnames(classes.placeholder__description, {
          [styles?.description]: !!styles?.description,
        })}
      >
        {description}
      </Typography>
      <LinkButton
        variant="text"
        onClick={onClickHandler}
        leftIcon={<SpriteIcon icon="add_circle" size={15} />}
        trackingData={trackingData}
        preventDefault
        stopPropagation
        disableUnderline
      >
        {t(`${TP}.m_ADD`)}
      </LinkButton>
    </div>
  );
};

const MediaSection = ({
  entity,
  mediaContent,
  media,
  type,
  posterTransformations,
  options,
  showMediaOptionsAlways = false,
  altText,
  titleText,
  isEmbedded,
  onClick,
  maxSingleMedia,
  showPoster,
  showAbout,
  actionLabel,
  totalMediaCount,
  renderMediaWidth,
  setIsVideoPlaying,
  isVideoPlaying,
  isPrimaryEntity,
  hasPhotoMedia,
  isEditMode,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { isMobile, isDesktop } = useDeviceTypeLayouts();
  const { navigate } = usePageContext();

  const { getSubPath } = useMemo(
    () =>
      isEditMode
        ? navigate.getLinkProps({
            entityType: ENTITY_TYPE.ARTIST,
            entity,
            edit: true,
          })
        : {},
    [entity, navigate, isEditMode],
  );

  const mediaMeta = useMemo(() => {
    const { production, title = '' } = media || {};

    return {
      title,
      ...(production && getProductionMeta(production)),
    };
  }, [media]);

  const onPlayHandler = useCallback(() => {
    setIsVideoPlaying(true);
  }, [setIsVideoPlaying]);

  const onPauseHandler = useCallback(() => {
    setIsVideoPlaying(false);
  }, [setIsVideoPlaying]);

  if (!media && !isEditMode) {
    return null;
  }
  const mediaURL = getMediaSource(media);

  if (type === MEDIA_SECTION_TYPE.POSTER) {
    if (!showPoster && !isEditMode) {
      return null;
    }

    return (
      <div
        className={classnames(classes.poster, {
          [classes.poster_primaryEntity]: !!isPrimaryEntity && !isDesktop,
        })}
      >
        {isEditMode && !mediaURL ? (
          <Placeholder
            icon="account_circle"
            description={t(`${TP}.FN_MW_PROFILE_PHOTO_DESCRIPTION`)}
            styles={{
              icon: classes.placeholder__icon_poster,
              description: classes.placeholder__description_poster,
              root: classes.placeholder__poster,
            }}
            linkProps={getSubPath({
              queryParams: {
                tab: artistEditTabValues.personal,
                [VALID_QUERY_PARAMS.OPEN_MODAL]: ARTIST_PROFILE_INFO_SECTIONS.PROFILE_PHOTO,
              },
              scrollTo: ARTIST_PROFILE_INFO_SECTIONS.PROFILE_PHOTO,
            })}
            trackingData={{
              ...mediaPosterTrackingData,
              subComponent: SUB_COMPONENTS.ADD_CTA,
            }}
          />
        ) : (
          <>
            <Image
              src={mediaURL}
              transformations={posterTransformations}
              alt={altText}
              title={titleText}
              lazy={false}
              onClick={() => onClick({ data: [media] })}
              preload
              trackingData={mediaPosterTrackingData}
              disableNextImage
              fetchPriority="high"
              {...(!isDesktop && { height: isMobile ? 115 : 194, width: isMobile ? 78 : 135 })}
            />
            {isEditMode && <MediaPageBtn isEditMode={isEditMode} type={type} />}
          </>
        )}
      </div>
    );
  }

  if (type === MEDIA_SECTION_TYPE.PHOTO) {
    if (!mediaURL && !isEditMode) {
      return null;
    }

    return isEditMode && !mediaURL ? (
      <Placeholder
        icon="photo_placeholder"
        description={t(`${TP}.FN_MW_COVER_PHOTO_DESCRIPTION`)}
        linkProps={getSubPath({
          queryParams: {
            tab: artistEditTabValues.personal,
            [VALID_QUERY_PARAMS.OPEN_MODAL]: ARTIST_PROFILE_INFO_SECTIONS.COVER_PHOTO,
          },
          scrollTo: ARTIST_PROFILE_INFO_SECTIONS.COVER_PHOTO,
        })}
        trackingData={{
          ...mediaImageTrackingData,
          subComponent: SUB_COMPONENTS.ADD_CTA,
        }}
        withBorder
      />
    ) : (
      <MediaImage
        production={media?.production}
        disabledProductionInfo={!showAbout || !isDesktop}
        actionLabel={actionLabel}
        mediaURL={mediaURL}
        mediaMeta={mediaMeta}
        options={options}
        showMediaOptionsAlways={showMediaOptionsAlways}
        altText={t(`${TP}.FN_SHOW_ALL_PHOTOS_OF_ENTITY`, { entityName: altText })}
        titleText={t(`${TP}.FN_SHOW_ALL_PHOTOS_OF_ENTITY`, { entityName: titleText })}
        isEmbedded={isEmbedded}
        onClick={() => onClick({ data: [media] })}
        maxSingleMedia={maxSingleMedia}
        totalMediaCount={totalMediaCount}
        renderMediaWidth={renderMediaWidth}
        isEditMode={isEditMode}
      />
    );
  }

  if (type === MEDIA_SECTION_TYPE.VIDEO) {
    if (!media?.url && isEditMode && !isDesktop && hasPhotoMedia) {
      return null;
    }

    return isEditMode && !media?.url ? (
      <Placeholder
        icon="video_placeholder_circle"
        description={t(`${TP}.FN_MW_INTRO_SHOWREEL_DESCRIPTION`)}
        styles={{
          description: classes.placeholder__description_video,
          root: classnames(classes.media, {
            [classes.placeholder__video]: !!hasPhotoMedia,
          }),
        }}
        linkProps={getSubPath({
          queryParams: {
            tab: artistEditTabValues.videos,
            [VALID_QUERY_PARAMS.OPEN_MODAL]: MEDIA_SECTIONS.INTRO_SHOWREEL,
          },
          scrollTo: MEDIA_SECTIONS.INTRO_SHOWREEL,
        })}
        trackingData={{
          ...mediaVideoTrackingData,
          subComponent: SUB_COMPONENTS.ADD_CTA,
        }}
        withBorder
      />
    ) : (
      <CardWithProductionInfo
        production={media?.production}
        disabled={!showAbout || !isDesktop}
        trackingData={mediaVideoTrackingData}
      >
        <div className={classnames(classes.media, classes.typeVideo)}>
          <VideoPlayer
            mediaContent={mediaContent}
            media={{ ...mediaMeta, ...media }}
            onPlay={onPlayHandler}
            onPause={onPauseHandler}
            height={296}
            width={renderMediaWidth}
            lazy={false}
            useIntersectionObserver={false}
            fetchpriority="high"
            preload
            hideOverlay
            showVideoInfo={!media?.production?.id}
            trackingData={mediaVideoTrackingData}
          />
          {(isEditMode || (!isVideoPlaying && isDesktop)) && (
            <MediaPageBtn
              btnLabel={actionLabel}
              type={type}
              options={options}
              showDropdownAlways={showMediaOptionsAlways}
              isEmbedded={isEmbedded}
              maxSingleMedia={maxSingleMedia}
              totalMediaCount={totalMediaCount}
              isEditMode={isEditMode}
            />
          )}
        </div>
      </CardWithProductionInfo>
    );
  }

  return null;
};

const BreadcrumbComponent = ({ breadcrumbs, entityType, entity }) => (
  <div className={classes.breadcrumbs}>
    <Breadcrumbs data={breadcrumbs} maxItems={1} overlay />
    {entityType === ENTITY_TYPE.ARTIST && (
      <CastingToolPromo
        entityType={entityType}
        entity={entity}
        asCTA
        trackingData={{ ...mediaImageTrackingData, component: COMPONENTS.VIEW_IN_CASTING_TOOL_CTA }}
      />
    )}
  </div>
);

const MediaWidget = ({ entityType, entity, isEmbedded, showLightbox, breadcrumbs, isEditMode }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate } = usePageContext();
  const { isDesktop } = useDeviceTypeLayouts();
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const isPrimaryEntity = [ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION].includes(entityType);
  const { data, isLoading: isMediaLoading } = useQuery(
    queries.getMediaSuggestions({ entityType, entityId: entity?.id }),
  );

  const media = data?.data;
  const {
    poster,
    video,
    photo,
    hasPoster,
    hasVideo,
    hasPhoto,
    shouldShowPhoto,
    mediaPageBtn,
    showPoster: shouldShowPoster,
    showBorder: shouldShowBorder,
  } = useMemo(() => {
    let posterMedia = null;
    let posterTransformations = null;
    let mapLocation = null;

    const photoMedia = media?.find(content => content?.mediaType === 'image');

    const firstPlayableVideo = media?.find(({ playable }) => playable);

    switch (entityType) {
      case ENTITY_TYPE.ARTIST: {
        posterMedia = { id: entity?.id, file: { urls: entity?.image } };
        posterTransformations = TRANSFORMATIONS.PROFILE_IMAGE;

        break;
      }
      case ENTITY_TYPE.ORGANIZATION: {
        posterMedia = { id: entity?.id, file: { urls: entity?.logo } };
        posterTransformations = TRANSFORMATIONS.ORG_LOGO;

        break;
      }
      case ENTITY_TYPE.VENUE: {
        mapLocation = entity?.lat && entity?.lon;
        break;
      }
      case ENTITY_TYPE.PRODUCTION: {
        posterMedia = { id: entity?.id, file: entity?.poster?.file };
        posterTransformations = TRANSFORMATIONS.POSTER;
        break;
      }
      default: {
        break;
      }
    }

    const videos = entity?.stats?.media?.videos?.total || 0;
    const images = entity?.stats?.media?.images?.total || 0;
    const total = entity?.stats?.media?.total || 0;

    const imageCTALabel = !isDesktop
      ? t(`${TP}.FN_TAB_PHOTOS`)
      : t(`${TP}.FN_SHOW_ALL_PHOTOS`, {
          ...(images > 1 && { number: `(${images})` }),
        });
    const videoCTALabel = !isDesktop
      ? t(`${TP}.OPERA_ONLINE`)
      : t(`${TP}.FN_SHOW_ALL_VIDEOS`, {
          ...(videos > 1 && { number: `(${videos})` }),
        });

    const filteredMediaOptions = MEDIA_OPTIONS.reduce((acc, item) => {
      if (item.type === MEDIA_SECTION_TYPE.PHOTO && entity?.stats?.media?.images?.exists && !!photoMedia) {
        acc.push({ ...item, label: images > 1 ? imageCTALabel : '' });
      }

      if (item.type === MEDIA_SECTION_TYPE.VIDEO && entity?.stats?.media?.videos?.exists && !!firstPlayableVideo) {
        acc.push({ ...item, label: videos > 1 ? videoCTALabel : '' });
      }

      return acc;
    }, []);

    const mediaOptionsList = filteredMediaOptions?.map(item => ({
      ...item,
      linkProps: navigate.getLinkProps({ entityType, entity, path: item.value }),
    }));

    const showMediaOptionsAlways = !(!!firstPlayableVideo && !!photoMedia && !isEditMode);
    const hasPosterMedia = !!posterMedia?.file?.urls?.medium || !!mapLocation;
    const hasPhotoMedia = !!photoMedia;
    const hasVideoMedia = !!firstPlayableVideo;
    const showPhotoBlock = (isDesktop && isEditMode) || (hasPhotoMedia && !(!isDesktop && hasVideoMedia));

    let renderMediaWidth = hasPosterMedia || isEditMode ? 1076 : 1280;

    if (hasVideoMedia && showPhotoBlock) {
      renderMediaWidth = hasPosterMedia || isEditMode ? 536 : 638;
    }

    const posterMediaUrl = getMediaSource(posterMedia);
    let showPoster = !!posterMediaUrl;

    let showAbout = media?.some(prod => prod?.production?.id);
    const showBorder =
      isEditMode && (!showPoster || (isDesktop ? !hasVideoMedia || !hasPhotoMedia : !hasVideoMedia && !hasPhotoMedia));

    if (!isDesktop) {
      if (entityType === ENTITY_TYPE.ORGANIZATION) {
        showPoster = !!posterMediaUrl;
      }
      if (entityType === ENTITY_TYPE.PRODUCTION) {
        showAbout = !showPoster;
      }
    }

    return {
      poster: (
        <MediaSection
          type={MEDIA_SECTION_TYPE.POSTER}
          showAbout={showAbout}
          showPoster={showPoster}
          media={posterMedia}
          posterTransformations={posterTransformations}
          altText={entity?.name}
          titleText={entity?.name}
          onClick={({ ...args }) => showLightbox({ ...args, trackingData: mediaPosterTrackingData })}
          mediaContent={entity?.metaInfo}
          maxSingleMedia={!isDesktop}
          isPrimaryEntity={isPrimaryEntity}
          hasVideo={hasVideoMedia}
          isEditMode={isEditMode}
          entity={entity}
        />
      ),
      video: (
        <MediaSection
          showAbout={showAbout}
          showPoster={showPoster}
          type={MEDIA_SECTION_TYPE.VIDEO}
          media={firstPlayableVideo}
          options={mediaOptionsList}
          showMediaOptionsAlways={showMediaOptionsAlways}
          isEmbedded={isEmbedded}
          mediaContent={entity?.metaInfo}
          maxSingleMedia={!isDesktop}
          actionLabel={videos > 1 ? videoCTALabel : ''}
          totalMediaCount={total}
          renderMediaWidth={renderMediaWidth}
          setIsVideoPlaying={setIsVideoPlaying}
          isVideoPlaying={isVideoPlaying}
          isPrimaryEntity={isPrimaryEntity}
          hasPhotoMedia={hasPhotoMedia}
          isEditMode={isEditMode}
          entity={entity}
        />
      ),
      photo: (
        <MediaSection
          showAbout={showAbout}
          showPoster={showPoster}
          type={MEDIA_SECTION_TYPE.PHOTO}
          media={photoMedia}
          options={mediaOptionsList}
          showMediaOptionsAlways={showMediaOptionsAlways}
          isEmbedded={isEmbedded}
          altText={entity?.name}
          titleText={entity?.name}
          onClick={({ ...args }) => showLightbox({ ...args, trackingData: mediaImageTrackingData })}
          maxSingleMedia={!isDesktop}
          mediaContent={entity?.metaInfo}
          actionLabel={images > 1 ? imageCTALabel : ''}
          totalMediaCount={total}
          renderMediaWidth={renderMediaWidth}
          isPrimaryEntity={isPrimaryEntity}
          isEditMode={isEditMode}
          entity={entity}
        />
      ),
      mediaPageBtn: (
        <MediaPageBtn
          type={MEDIA_SECTION_TYPE.PHOTO}
          options={mediaOptionsList}
          showDropdownAlways={showMediaOptionsAlways}
          isEmbedded={isEmbedded}
          maxSingleMedia={!isDesktop}
          btnLabel={images > 1 ? imageCTALabel : ''}
          totalMediaCount={total}
          isEditMode={isEditMode}
        />
      ),
      hasPoster: !!posterMedia?.file?.urls?.medium || !!mapLocation,
      hasVideo: hasVideoMedia,
      hasPhoto: hasPhotoMedia,
      shouldShowPhoto: showPhotoBlock,
      showBorder,
      showPoster,
    };
  }, [
    media,
    entityType,
    entity,
    isDesktop,
    t,
    showLightbox,
    isPrimaryEntity,
    isEmbedded,
    isVideoPlaying,
    navigate,
    isEditMode,
  ]);

  const className = useMemo(() => {
    if ((hasPoster && hasVideo && hasPhoto) || isEditMode) {
      return classnames(classes.allMediaWrapper, {
        ...(!!shouldShowBorder && {
          [classes.allMediaWrapper__showBorder]: true,
          [classes.allMediaWrapper__showBorder_video]: true,
          [classes.allMediaWrapper__showBorder_poster]: !!hasPoster,
        }),
      });
    }

    if (hasPoster && (hasVideo || hasPhoto)) {
      return classes.posterMediaWrapper;
    }

    if (hasVideo && hasPhoto && shouldShowPhoto) {
      return classes.landscapeMediaDoubleWrapper;
    }

    if (hasVideo || hasPhoto) {
      return classes.landscapeMediaWrapper;
    }

    return null;
  }, [hasPoster, hasVideo, hasPhoto, isEditMode, shouldShowPhoto, shouldShowBorder]);

  if (isMediaLoading) {
    return <Skeleton variant="rectangular" height={296} />;
  }

  if (!className) {
    return null;
  }

  const subText =
    entityType === ENTITY_TYPE.ORGANIZATION
      ? `${getLocation(entity)} | ${entity?.organizationType?.name}`
      : entity?.headline;

  const map = entityType === ENTITY_TYPE.VENUE && hasPoster && (
    <div className={classes.poster}>
      <MapView
        latitude={entity?.lat}
        longitude={entity?.lon}
        placeId={entity?.placeId}
        label={entity?.name}
        width={198}
        height={304}
      />
    </div>
  );

  return (
    <>
      {isEditMode && (
        <SectionBlock
          title={t(`${TP}.FN_MEDIA_WIDGET_HEADER_TITLE`)}
          description={t(`${TP}.FN_MEDIA_WIDGET_HEADER_DESCRIPTION`)}
          enableSeeAll={false}
          className={classes.mwHeaderSectionBlock}
          hideSectionDivider
        >
          {isDesktop && (
            <div className={classes.mwHeader}>
              <Typography>{t(`${TP}.FN_PROFILE_PHOTO`)}</Typography>
              <Typography>{t(`${TP}.FN_ARTIST_INTRO_SHOWREEL_SECTION`)}</Typography>
              <Typography>{t(`${TP}.FN_EDIT_COVER_PHOTO`)}</Typography>
            </div>
          )}
        </SectionBlock>
      )}
      <div
        className={classnames(classes.wrapper, {
          [classes.wrapper__withDottedBorder]: !!shouldShowBorder,
        })}
      >
        {breadcrumbs && !isDesktop && !isEditMode && (
          <BreadcrumbComponent breadcrumbs={breadcrumbs} entity={entity} entityType={entityType} isMediaWidgetExists />
        )}
        <div
          className={classnames(
            classes.mediaWidget,
            { [classes.productionMediaWidget]: entityType === ENTITY_TYPE.PRODUCTION },
            className,
          )}
        >
          {poster}
          {map}
          {video}
          {shouldShowPhoto && photo}
          {[ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION].includes(entityType) && !isDesktop && !isEditMode && (
            <div
              className={classnames(classes.content, {
                [classes.content_withPoster]: !!shouldShowPoster,
              })}
            >
              <div className={classes.gradient} />
              <div className={classes.background} />
              <Typography variant="p" weight="bold" size="20" color="white" className={classes.mediaWidget__title}>
                {entity?.name}
              </Typography>
              {subText && (
                <Typography variant="p" size="12" color="white" className={classes.mediaWidget__headline} truncate>
                  {subText}
                </Typography>
              )}

              {!isVideoPlaying && mediaPageBtn}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default withLightbox(MediaWidget);
