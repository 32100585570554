/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useCallback, useMemo, useState } from 'react';

import { GOOGLE_OLD_TRACKING_SERVICES, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton from 'components/uiLibrary/LinkButton';
import VideoPlayer from 'components/uiLibrary/VideoPlayer';
import Skeleton from 'components/uiLibrary/Loaders/Skeleton';
import Carousel from 'components/uiLibrary/Carousel';
import SeparatorList, { SEPARATOR_TYPES } from 'components/uiLibrary/SeparatorList';
import EntityName from 'components/Globals/EntityName';
import JsonLD, { JSON_LD_TYPES } from 'components/Globals/JsonLD';
import QuickPreview from 'components/Productions/Display/QuickPreview';
import useNewTracking from 'components/Globals/Analytics';
import ImpressionTracker from 'utils/components/impressionTracker';
import usePageContext from 'utils/hooks/usePageContext';

import { getComposerName, getComposerFromCreators } from 'utils/composer';
import { createDate, createDatewithTz, getTimezone } from 'utils/date';
import { getMediaSource } from 'utils/media';
import { useQuery } from 'utils/react-query';
import { trackVideoClick, trackOnVideoHover } from 'utils/tracking';

import queries from 'containers/Media/queries';

import {
  TP,
  CONTRIBUTORS,
  PROFESSION_IDS,
  VALIDATION_STATUS,
  PERFORMANCE_DATE_MODE_TYPES,
  DATE_FORMATS,
  REGEX_CONSTANTS,
  LSVOD_IMPRESSION_TRACKING_EVENTS,
  ENTITY_TYPE,
} from 'constants/index';

import { useTranslation } from 'src/i18n';

import classes from './DigitalVideoListing.module.scss';

const VIDEO_ACTION_BUTTONS = {
  POSTER: 'poster',
  TRAILER: 'trailer',
  WATCH_OPTIONS: 'watch_options',
  WATCH_ON_CUETV: 'watch_on_cuetv',
  LIVE_NOW: 'live_now',
  STREAM_OPTIONS: 'stream_options',
};

const useProductionTitle = production => {
  const customName = production?.name || '';

  if (customName) {
    return {
      title: customName,
      subtitle: '',
    };
  }

  const work = production?.productionWorks?.[0]?.work;
  const workTranslation = work?.name;

  let workName = work?.original_name || workTranslation;

  if (workTranslation?.length > 0 && workName !== workTranslation) {
    if (workName?.length > 0) {
      workName += ' - ';
    }

    workName += workTranslation;
  }

  return {
    title: workName,
    subtitle: getComposerName(getComposerFromCreators(work?.creators)?.profile),
  };
};

const useProducer = production => {
  const producer = production?.contributions?.find(
    ({ contributionType }) => contributionType === CONTRIBUTORS.PRODUCER,
  );

  return {
    producer: producer?.organization?.name || producer?.profile?.name,
    season: production?.years?.[0],
    logo: {
      src: getMediaSource({
        id: producer?.organization?.id,
        file: { urls: producer?.organization?.image },
      }),
      blurHash: producer?.organization?.image?.blurHash,
    },
  };
};

const usePrimaryCrew = production => {
  const { directors = [], conductors = [] } =
    production?.contributions?.reduce(
      (acc, contributor) => {
        if (contributor?.contributionType === CONTRIBUTORS.CREW) {
          if (contributor?.profession?.id === PROFESSION_IDS.STAGE_DIRECTOR) {
            acc.directors.push({
              name: contributor?.profile?.name,
              id: contributor?.profile?.id,
              entityType: ENTITY_TYPE.ARTIST,
            });
          } else if (contributor?.profession?.id === PROFESSION_IDS.CONDUCTOR) {
            acc.conductors.push({
              name: contributor?.profile?.name,
              id: contributor?.profile?.id,
              entityType: ENTITY_TYPE.ARTIST,
            });
          }
        }

        return acc;
      },
      { directors: [], conductors: [] },
    ) || {};

  const primaryCrew = [...directors, ...conductors];

  return primaryCrew;
};

const useUpcomingPerformance = production => {
  const nextDigitalPerformance = production?.performances?.filter(
    ({ mode, isCancelled, startDate }) =>
      [PERFORMANCE_DATE_MODE_TYPES.LIVESTREAM, PERFORMANCE_DATE_MODE_TYPES.VOD].includes(mode) &&
      !isCancelled &&
      startDate,
  )?.[0];

  if (!nextDigitalPerformance) {
    return null;
  }

  let datetime = nextDigitalPerformance?.startDate;
  if (nextDigitalPerformance?.startTime) {
    datetime += ` ${nextDigitalPerformance?.startTime}`;
  }

  const dateWithTimezone = createDatewithTz(datetime, nextDigitalPerformance?.timezone);
  const date = dateWithTimezone.format(DATE_FORMATS.DATE_MONTH);
  const day = createDate(dateWithTimezone).format(DATE_FORMATS.FULL_DAY);

  let timeLabel;

  if (nextDigitalPerformance?.startTime) {
    const time = dateWithTimezone.format(DATE_FORMATS.TIME);
    const timezone = getTimezone(dateWithTimezone);

    timeLabel = `${time} ${timezone}`;
  }

  return {
    date,
    day,
    time: timeLabel,
  };
};

const useCheckRedMask = production => production?.validationStatus?.id === VALIDATION_STATUS.APPROVED;

const useWatchOptionDetails = production => {
  const today = createDate().startOf('day');
  let isFree = false;
  let isLive = false;
  let hasLiveStream = false;
  let hasCueTvMedia = false;

  const productionPerformances = production?.performances || [];

  productionPerformances.forEach(({ mode, isCancelled, attributes, startDate }) => {
    if (
      [PERFORMANCE_DATE_MODE_TYPES.LIVESTREAM, PERFORMANCE_DATE_MODE_TYPES.FULL_VIDEO].includes(mode) &&
      !isCancelled
    ) {
      if (mode === PERFORMANCE_DATE_MODE_TYPES.LIVESTREAM) {
        hasLiveStream = true;
      }

      (attributes || []).forEach(attribute => {
        if (attribute?.paymentType === 'free') {
          isFree = true;
        }

        if (attribute?.url?.match(REGEX_CONSTANTS.CUETV_URL)) {
          hasCueTvMedia = true;
        }
      });

      if (
        startDate &&
        createDate(startDate)
          .startOf('day')
          .isSame(today)
      ) {
        isLive = true;
      }
    }
  });

  let type = `${TP}.FN_WATCH_OPTIONS`;
  let event = VIDEO_ACTION_BUTTONS.WATCH_OPTIONS;

  if (isLive) {
    type = `${TP}.FN_LIVE_NOW`;
    event = VIDEO_ACTION_BUTTONS.LIVE_NOW;
  }
  if (hasLiveStream) {
    type = `${TP}.FN_STREAM_OPTIONS`;
    event = VIDEO_ACTION_BUTTONS.STREAM_OPTIONS;
  }
  if (hasCueTvMedia) {
    type = `${TP}.FN_WATCH_ON_CUETV`;
    event = VIDEO_ACTION_BUTTONS.WATCH_ON_CUETV;
  }

  return {
    ctaLabel: type,
    ctaEvent: event,
    isLive,
    isFree,
  };
};

const DigitalVideoCard = ({ data, profile, onOpenTrailerModal, onOpenDigitalModal, onMouseEnter, trackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { title, subtitle } = useProductionTitle(data?.production);
  const { navigate } = usePageContext();
  const { producer, season, logo } = useProducer(data?.production);
  const primaryCrews = usePrimaryCrew(data?.production);
  const isRedMaskProduction = useCheckRedMask(data?.production);
  const upcomingPerformance = useUpcomingPerformance(data?.production);
  const { ctaLabel, ctaEvent, isLive, isFree } = useWatchOptionDetails(data?.production);
  const isProductionArchived = data?.production?.isArchived;

  return (
    <ImpressionTracker
      data={{
        entityId: data?.production?.id,
        entityName: title,
        entityType: ENTITY_TYPE.PRODUCTION, // Old one was "lsvod"
        ...trackingData,
      }}
      eventType={LSVOD_IMPRESSION_TRACKING_EVENTS.LSVOD_CARD_IMPRESSION}
      as="div"
      className={classes.card}
    >
      <JsonLD type={upcomingPerformance?.mode === 'ls' ? JSON_LD_TYPES.LIVESTREAM : JSON_LD_TYPES.VOD} entity={data} />
      <div
        className={classes.card_poster}
        onMouseEnter={() => onMouseEnter(data)}
        onClick={() => onOpenDigitalModal(data, ctaEvent)}
      >
        <Image
          transformations={TRANSFORMATIONS.POSTER}
          src={data?.poster?.large}
          blurHash={data?.blurHash}
          height={244}
          width={165}
          alt={title}
          title={title}
          useIntersectionObserver
          lazy
          disableNextImage
        />

        {season && (
          <Typography variant="p" className={classes.card_season} weight="bold" size="12" color="white">
            {season}
            <Typography variant="p" size="12" color="white" className={classes.card_payment}>
              {isFree ? t(`${TP}.FN_FREE`) : t(`${TP}.PAID`)}
            </Typography>
          </Typography>
        )}
        {logo?.src && (
          <div className={classes.card_logo}>
            <Image
              transformations={TRANSFORMATIONS.POSTER}
              height={25}
              width={18}
              src={logo?.src}
              blurHash={logo?.blurHash}
              disableNextImage
              useIntersectionObserver
            />
          </div>
        )}

        <div className={classes.card_overlay}>
          <div>
            {upcomingPerformance && (
              <div className={classes.card_info}>
                <Typography variant="p" size="12" color="white">
                  {t(`${TP}.FN_AVAILABLE_ON`)}
                </Typography>
                <Typography variant="p" size="12" weight="medium" color="white">
                  {upcomingPerformance?.date}
                </Typography>
                <Typography variant="p" size="12" color="white">
                  {upcomingPerformance?.day}
                </Typography>
                <Typography variant="p" size="12" color="white">
                  {upcomingPerformance?.time}
                </Typography>
              </div>
            )}
          </div>

          <div className={classes.card_actions}>
            {data?.production?.hasTrailers && (
              <LinkButton
                variant="overlay"
                shape="rounded"
                size="medium"
                leftIcon={<SpriteIcon icon="play_circle" />}
                onClick={() => onOpenTrailerModal(data)}
                stopPropagation
                trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.WATCH_TRAILER_CTA }}
              >
                {t(`${TP}.FN_TRAILER`)}
              </LinkButton>
            )}
            <LinkButton
              variant="overlay"
              shape="rounded"
              size="medium"
              {...(isLive ? { leftIcon: <SpriteIcon icon="live_tv" /> } : {})}
              trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.STREAM_OPTIONS_CTA }}
            >
              {t(ctaLabel)}
            </LinkButton>
          </div>
        </div>
      </div>
      <LinkButton
        {...navigate.getLinkProps({ entity: data?.production, entityType: ENTITY_TYPE.PRODUCTION })}
        variant="text"
        disableHover
        disableUnderline
        styles={{ root: classes.cardDetailsLink }}
        isLink
        trackingData={{ ...trackingData }}
      >
        <div className={classes.card_details}>
          <Typography truncate>
            {!profile && isRedMaskProduction && <SpriteIcon icon="red_mask" className={classes.redMaskIcon} />}
            <Typography weight="bold">{title}</Typography>
            {subtitle && (
              <Typography color="secondary" italic>
                {', '}
                {subtitle}
              </Typography>
            )}
          </Typography>
          <Typography truncate color="secondary" blur={isProductionArchived}>
            {producer}
            {season && ` (${season})`}
            {','}
          </Typography>
          {primaryCrews.length > 0 && (
            <Typography truncate color="secondary" blur={isProductionArchived}>
              <SeparatorList
                separator={SEPARATOR_TYPES.COMMA}
                data={primaryCrews?.map(primaryCrew => (
                  <EntityName
                    key={primaryCrew?.id}
                    entityType={primaryCrew.entityType}
                    entity={{ id: primaryCrew?.id, name: primaryCrew?.name }}
                    eventType={primaryCrew.entityType + LSVOD_IMPRESSION_TRACKING_EVENTS.LSVOD_CARD_IMPRESSION}
                    isRaw
                    trackingData={{ ...trackingData }}
                  />
                ))}
              />
            </Typography>
          )}
        </div>
      </LinkButton>
    </ImpressionTracker>
  );
};

const DigitalVideoListing = ({
  data = [],
  count,
  isLoading,
  slider = false,
  sliderOptions = {},
  trackingData: newTrackingData,
}) => {
  const [openTrailerModal, setOpenTrailerModal] = useState(false);
  const [openDigitalModal, setOpenDigitalModal] = useState(false);
  const [productionId, setProductionId] = useState(null);
  const [trailerMedia, setTrailerMedia] = useState(null);
  const track = useNewTracking();

  const getTrackingData = useCallback(
    (media, url) => ({
      media_id: media?.id,
      production_id: media?.production?.id,
      location: VIDEO_ACTION_BUTTONS.POSTER,
      production_name: media?.production?.name,
      video_url: url,
    }),
    [],
  );

  const videos = useMemo(() => {
    if (!count) {
      return data;
    }

    if (isLoading) {
      return [...Array(count)];
    }

    return data?.slice(0, count);
  }, [data, count, isLoading]);

  const onCardClicked = useCallback(
    (tType, media, url) => {
      const trackingData = getTrackingData(media, url);
      track.click(trackVideoClick(tType, trackingData), GOOGLE_OLD_TRACKING_SERVICES);
      track.click({ ...newTrackingData, subComponent: SUB_COMPONENTS.VOD_CARDS });
    },
    [getTrackingData],
  );

  const onOpenTrailerModal = useCallback(media => {
    setTrailerMedia(media);
    setOpenTrailerModal(true);
  }, []);

  const onCloseTrailerModal = useCallback(() => {
    setTrailerMedia(null);
    setOpenTrailerModal(false);
  }, []);

  const onOpenDigitalModal = useCallback(
    (lsvod, event) => {
      onCardClicked(event, lsvod);
      setProductionId(lsvod?.production?.id);
      setOpenDigitalModal(true);
    },
    [onCardClicked],
  );

  const onCloseDigitalModal = useCallback(() => {
    setProductionId(null);
    setOpenDigitalModal(false);
  }, []);

  const onMouseEnter = useCallback(
    media => {
      const trackingData = getTrackingData(media);
      track.click(trackOnVideoHover(trackingData), GOOGLE_OLD_TRACKING_SERVICES);
    },
    [getTrackingData],
  );

  const { data: trailer } = useQuery(
    queries.getProductionTrailer({
      id: trailerMedia?.production?.id,
      queryConfig: {
        enabled: !!trailerMedia?.production?.id,
        onSuccess: response => {
          const trackingData = getTrackingData(trailerMedia, response?.url);
          track.click(trackVideoClick(VIDEO_ACTION_BUTTONS.TRAILER, trackingData), GOOGLE_OLD_TRACKING_SERVICES);
        },
      },
    }),
  );

  return (
    <div className={classes.root}>
      <Carousel options={{ gap: 10, blueArrow: true, ...sliderOptions }} disabled={!slider}>
        {videos?.map((item, index) => {
          if (isLoading) {
            return <Skeleton key={`${index}_loader`} variant="rectangular" width={165} height={328} />;
          }

          return (
            <DigitalVideoCard
              key={index}
              data={item}
              index={index}
              isLoading={isLoading}
              onOpenTrailerModal={onOpenTrailerModal}
              onOpenDigitalModal={onOpenDigitalModal}
              onMouseEnter={onMouseEnter}
              openDigitalModal={openDigitalModal}
              trackingData={{ ...newTrackingData, entityId: item?.id }}
            />
          );
        })}
      </Carousel>
      {openTrailerModal && <VideoPlayer media={trailer} isPlaying onClose={onCloseTrailerModal} />}
      {openDigitalModal && (
        <QuickPreview isOpen={openDigitalModal} productionId={productionId} setIsOpen={onCloseDigitalModal} />
      )}
    </div>
  );
};

export default DigitalVideoListing;
