import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import LinkButton, { TertiaryButton } from 'components/uiLibrary/LinkButton';
import ProSlug from 'components/Productions/Display/ProSlug';
import TicketButton from 'components/Productions/Display/TicketButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import usePageContext from 'utils/hooks/usePageContext';

import classes from './PreviewEntityBlock.module.scss';

const PreviewEntityBlock = ({
  children,
  production,
  linkProps,
  ctaLabel,
  disabled,
  hideLinkProps = false,
  hideTicketButton = false,
  trackingData,
  ctaTrackingData,
}) => {
  const hideProductionSlug = useMediaQuery('(max-width: 800px)');
  const { entity } = usePageContext();

  if (disabled) {
    return children;
  }

  return (
    <div className={classes.root}>
      {!hideProductionSlug && production && (
        <div>
          <ProSlug
            profile={entity}
            production={production}
            shouldSectionExpand={false}
            showWorkDetails
            redirectToProductionPage
            trackingData={trackingData}
          />
        </div>
      )}
      <div className={classes.main}>
        <div className={classes.main_content}>{children}</div>
        <div className={classes.main_actions}>
          {ctaLabel && linkProps && !hideLinkProps && (
            <>
              <TertiaryButton
                shape="rectangle"
                styles={{
                  root: classes.ctaLink,
                }}
                {...linkProps}
                rightIcon={<SpriteIcon icon="chevron_right" size={18} />}
                isLink
                scroll
                trackingData={ctaTrackingData}
              >
                {ctaLabel}
              </TertiaryButton>
              <LinkButton
                leftIcon={<SpriteIcon icon="play_circle" size={18} className={classes.mobileCtaIcon} />}
                styles={{ root: classes.mobileCtaLink }}
                {...linkProps}
                isLink
                trackingData={ctaTrackingData}
              >
                {ctaLabel}
              </LinkButton>
            </>
          )}
          {production && !hideTicketButton && (
            <div className={classes.ticketLink}>
              <TicketButton production={production} trackingData={trackingData} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewEntityBlock;
